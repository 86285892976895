import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import PostCard from "../../component/post/postCard/PostCard";
import { dbService } from "../../fbase";

export default function MyPosts({ userObj, building }) {
  const { userUID } = userObj;
  const [myPosts, setMyPosts] = useState([]);
  useEffect(async () => {
    let arr = [];
    building.map(async (e, i) => {
      const allbuildings = query(
        collection(dbService, "buildings", e.id, "posts"),
        where("postUID", "==", userUID)
      );
      const myPostsDoc = await getDocs(allbuildings);
      myPostsDoc.forEach((doc) => {
        // console.log(doc.data());
        arr.push({ ...doc.data(), id: doc.id });
      });
    });
    const questionRef = query(
      collection(dbService, "buildings", "question", "posts"),
      where("postUID", "==", userUID)
    );
    const questionDocs = getDocs(questionRef);
    questionDocs
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // console.log(doc.data());
          arr.push({ ...doc.data(), id: doc.id });
        });
      })
      .then(() => {
        // console.log(arr);
        arr.sort(function (a, b) {
          // Compare the 2 dates
          if (a.postCreatedTime.seconds > b.postCreatedTime.seconds) return -1;
          if (a.postCreatedTime.seconds < b.postCreatedTime.seconds) return 1;
          return 0;
        });
        // console.log(arr);
      })
      .then(() => {
        setMyPosts(arr);
      })
      .catch(console.error);
  }, []);
  console.log(myPosts);
  return (
    <Container>
      {myPosts.length === 0 ? (
        <h6 style={{ marginTop: "30px", textAlign: "center" }}>
          아직 작성한 글이 없네요..😅
          <br />
          하나 작성하러 가보시는 건 어때요?
        </h6>
      ) : (
        myPosts.map((e, i) => <PostCard data={e} key={i} />)
      )}
    </Container>
  );
}
