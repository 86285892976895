import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Boarddata } from "../../data/data";
import EventNotice from "../modal/EventNotice";
export default function Boards() {
  const navigate = useNavigate();
  const [clicked, setClicked] = useState(false);
  return (
    <>
      <GridBox
        style={{
          paddingTop: "50px",
          paddingLeft: "30px",
          paddingRight: "30px",
        }}
      >
        {Boarddata.map((e, i) => {
          if (e.img === "event") {
            return (
              <EachBoard onClick={() => setClicked(true)} key={i}>
                <Circle>
                  <img src={`/img/boards/icon_${e.img}.png`} alt="each icon" />
                </Circle>
                <Text>{e.name}</Text>
              </EachBoard>
            );
          }
          return (
            <EachBoard onClick={() => navigate(`/board/${e.img}`)} key={i}>
              <Circle>
                <img src={`/img/boards/icon_${e.img}.png`} alt="each icon" />
              </Circle>
              <Text>{e.name}</Text>
            </EachBoard>
          );
        })}
      </GridBox>
      {clicked ? <EventNotice setClicked={setClicked} /> : null}
    </>
  );
}
const GridBox = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
`;
const EachBoard = styled.div`
  display: flex;
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
`;
const Circle = styled.div`
  width: 80%;
  border-radius: 50%;
  height: 0;
  padding-bottom: 80%;
  background-color: whitesmoke;
  text-align: center;
  box-shadow: rgb(0 0 0 / 25%) 0px 7px 10px;
  overflow: hidden;
  img {
    width: 100%;
  }
`;
const Text = styled.span`
  margin-top: 10px;
`;
