import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { dbService } from "../../fbase";
import { MAIN_PINK, TEXT_GRAY } from "../../color";
import HotPostCard from "../../component/post/postCard/HotPostCard";

export default function Hot() {
  // const [tab, setTab] = useState(0);
  const [post, setPost] = useState([]);
  useEffect(() => {
    const hotRef = query(
      collection(dbService, "hots"),
      orderBy("hotCreatedTime", "desc")
    );
    const hotDocs = getDocs(hotRef);
    let arr = [];
    hotDocs
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          arr.push({ ...doc.data(), id: doc.id });
        });
      })
      .then(() => {
        setPost(arr);
      })
      .catch(console.error);
  }, []);
  return (
    <Container>
      {/* <Top>
        <h6>자유롭게 학교 시설에 대해 질문하는 공간</h6>
        <p>질문에 답글을 달아주시면 30 KUKIE를 드립니다.</p>
      </Top> */}
      {/* <TabBar>
        <Tab onClick={() => setTab(0)} active={tab === 0}>
          최근
        </Tab>
        <span className="sep">|</span>
        <Tab onClick={() => setTab(1)} active={tab === 1}>
          인기
        </Tab>
      </TabBar> */}
      {post.map((e, i) => (
        <HotPostCard data={e} key={i} />
      ))}
    </Container>
  );
}
const Top = styled.div`
  margin-top: 20px;
  padding: 10px;
  width: 95%;
  margin: auto;
  border-radius: 20px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: white;
`;
const Btn = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: ${MAIN_PINK};
  color: white;
  border-radius: 20px;
  padding: 5px 10px;
`;
const TabBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 15px;
  .sep {
    margin: 0 5px;
  }
`;
const Tab = styled.div`
  color: ${(props) => (props.active ? MAIN_PINK : TEXT_GRAY)};
  cursor: pointer;
`;
