import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { frequentSearch } from "../../data/data";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { MAIN_PINK, MAIN_GRAY } from "../../color";

export default function Search() {
    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const onSearchBtnClicked = () => {
        console.log(search);
        alert(`당신은 ${search}를 검색하셨습니다`);
    };
    const onFormSubmit = (e) => {
        e.preventDefault();
        console.log(search);
        alert(`당신은 ${search}를 검색하셨습니다`);
    };
    return (
        <Container style={{ padding: "30px" }}>
            <SearchBox onSubmit={onFormSubmit}>
                <InputBox
                    type="text"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
                <SearchBtn onClick={onSearchBtnClicked}>
                    <img src="/img/footerBtn/btn_search_white.png" />
                </SearchBtn>
            </SearchBox>
            <h5>자주 찾는 검색어</h5>
            <Frequent>
                {frequentSearch.map((e, i) => (
                    <Each key={i}>{e}</Each>
                ))}
            </Frequent>
            <DownWard>
                <FontAwesomeIcon
                    onClick={() => navigate(-1)}
                    icon={faAngleDown}
                    color={MAIN_PINK}
                    size="xl"
                />
            </DownWard>
        </Container>
    );
}
const SearchBox = styled.form`
    margin-bottom: 40px;
    display: flex;
    box-shadow: rgb(0 0 0 / 25%) 0px 7px 10px;
    border-radius: 17px;
    padding: 10px 5px;
    background-color: white;
`;
const InputBox = styled.input`
    border: 0;
    width: 90%;
    padding-left: 15px;
    :focus {
        outline: 0;
    }
`;
const SearchBtn = styled.div`
    width: 10%;
    /* background-color: ${MAIN_GRAY}; */
    img {
        width: 80%;
        filter: opacity(0.5) drop-shadow(0 0 0 red);
    }
`;
const Frequent = styled.div`
    display: flex;
    flex-wrap: wrap;
`;
const Each = styled.div`
    border-radius: 50px;
    background: #dddddd;
    margin: 5px;
    margin-left: 0px;
    padding: 5px 10px;
`;
const DownWard = styled.div`
    font-size: 30px;
    position: absolute;
    bottom: 100px;
    display: flex;
    left: 50%;
    transform: translateX(-50%);
`;
