import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { MAIN_PINK, TEXT_GRAY } from "../../color";
import BuildingCard from "../../component/building/BuildingCard";
export default function Building({ building }) {
  const [tab, setTab] = useState(0);
  const filterData = (num) => {
    let type;
    switch (num) {
      case 1:
        type = "인문계";
        break;

      case 2:
        type = "이공계";
        break;

      case 3:
        type = "녹지";
        break;
      case 4:
        type = "의과대 및 기숙사";
        break;
    }
    const filterdData = building.filter((e) => e.buildingCategory === type);
    return filterdData;
  };
  return (
    <Container>
      <Row
        style={{
          alignItems: "center",
          marginTop: "10px",
          marginBottom: "30px",
        }}
      >
        <Col xs={3}>
          <Map onClick={() => alert("정식 버전에서 다시 만나요!😉")}>
            학교 지도
          </Map>
        </Col>
        <Col xs={9}>
          <TabBar>
            <Tab onClick={() => setTab(0)} active={tab === 0}>
              전체
            </Tab>
            <span className="sep">|</span>
            <Tab onClick={() => setTab(1)} active={tab === 1}>
              인문계
            </Tab>
            <span className="sep">|</span>
            <Tab onClick={() => setTab(2)} active={tab === 2}>
              이공계
            </Tab>
            <span className="sep">|</span>
            <Tab onClick={() => setTab(3)} active={tab === 3}>
              녹지
            </Tab>
            <span className="sep">|</span>
            <Tab onClick={() => setTab(4)} active={tab === 4}>
              의과대 및 기숙사
            </Tab>
          </TabBar>
        </Col>
      </Row>
      {tab === 0
        ? building.map((e, i) => <BuildingCard data={e} key={i} />)
        : filterData(tab).map((e, i) => <BuildingCard data={e} key={i} />)}
    </Container>
  );
}
const Map = styled.div`
  cursor: pointer;
  font-size: 12px;
  background: ${MAIN_PINK};
  color: white;
  border-radius: 10px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
`;
const TabBar = styled.div`
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  justify-content: space-between;
  .sep {
    font-size: 12px;
  }
`;
const Tab = styled.div`
  font-size: 12px;
  color: ${(props) => (props.active ? MAIN_PINK : TEXT_GRAY)};
  cursor: pointer;
`;
