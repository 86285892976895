import { deleteUser, EmailAuthProvider, reauthenticateWithCredential, signInWithEmailAndPassword, updateEmail } from "firebase/auth";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { Navigate, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { authService ,dbService} from "../../fbase";


export default function Cancelmembership({userObj}) {
  
  
  const [password, setPassword] = useState("");

  const onChange = (event) => {
    const {
      target: { name, value },
    } = event;
    console.log(name,value);
  if (name === "password") {
      setPassword(value);
      console.log(value);
    }
  };


  const onSubmit = async (event) => {
    event.preventDefault();
    try {


      if(password === ""){
        alert("모든 정보를 입력해주세요!")
        return;
      }

      const auth = authService;
      const user =auth.currentUser;
      
      console.log(password);

      


      // TODO(you): prompt the user to re-provide their sign-in credentials
const credential = EmailAuthProvider.credential(userObj.userEmail, password)

reauthenticateWithCredential(user, credential).then(() => {
  // User re-authenticated.
  console.log("재인증 성공")


  
deleteDoc(doc(dbService, "users", userObj.userUID)).then(() => {
    // User deleted.
    deleteUser(user).then(() => {
    // User deleted.
    alert("탈퇴 되셨습니다!")
    Navigate("/")
  }).catch((error) => {
    // An error ocurred
    // ...
    alert("문제가 발생하였습니다.(1)")
  });
  

}).catch((error) => {
  // An error ocurred
  // ...
  console.log("error while delete firestore doc")
    alert("문제가 발생하였습니다.(2)")
  return;
});


  }).catch((error) => {
    // An error ocurred
    // ...
    
    

    console.log("재인증 실패")
  alert("비밀번호가 맞지 않습니다!")
  return;
  });;




  



      
    }  catch (error) {
        console.log(error);
        
        alert("문제가 발생하였습니다.(3)")
        //setError(error.message);
    }
  }

  
  return (
    <Container
      style={{
        height: window.innerHeight,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "whitesmoke",
      }}
    >
      <Logo>
        <span>회원</span> 탈퇴
      </Logo>
      <CancelmembershipForm onSubmit={onSubmit}>
        
        <PwInput  name="password" type={"password"} placeholder="계정 비밀번호" onChange={onChange}/>
       
        <CancelmembershipBtn type={"submit"} value="탈퇴하기" />
        
      </CancelmembershipForm>
     
    </Container>
  );
}
const Logo = styled.div`
  height: 30%;
  padding-top: 30%;
  color: red;
  font-size: 50px;
  text-transform: uppercase;
  span {
    font-weight: bolder;
  }
`;
const CancelmembershipForm = styled.form`
  height: 60%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-top: 20%;
  a {
    text-decoration: none;
  }
  a,
  p {
    font-size: 12px;
  }
  .red {
    color: red;
  }
`;
const EmailInput = styled.input`
  width: 80%;
  font-size: 15px;
  height: 40px;
  padding: 0 15px;
  border-radius: 15px;
  border: 0;
  box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.4);
  margin-bottom: 20px;
`;
const PwInput = styled(EmailInput)``;
const CancelmembershipBtn = styled.input`
  color: white;
  background-color: red;
  border-radius: 20px;
  padding: 5px 30px;
  border: 0;
  margin-bottom: 20px;
`;

