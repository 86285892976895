import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { MAIN_GRAY, MAIN_PINK, TEXT_GRAY } from "../../color";
export default function BuildingCard({ data }) {
  const navigate = useNavigate();
  const onClick = () => {
    navigate(`/building/${data.id}`);
    window.scroll(0, 0);
  };
  return (
    <>
      <Box onClick={onClick}>
        <Left>
          <BuildImg>
            {data.id === "jV1mfNMCgdAxxaaL9bEy" ||
            data.id === "rYkd1w1GiWSMH2aCdOrf" ||
            data.id === "vTwGqaUwNODTQCz0RMqq" ||
            data.id === "Rw0h16ml2CHsoQVOuBVa" ? (
              <img
                src={
                  "https://kuku-keke.com/wp-content/uploads/2020/03/1766_4.png"
                }
                alt="no picture"
              />
            ) : (
              <img src={`/img/buildings/${data.id}.png`} alt="no picture" />
            )}
          </BuildImg>
          <Content>
            <span className="name">{data.buildingName}</span>
            <span className="location">{data.buildingCategory}</span>
          </Content>
        </Left>
        <Arrow>
          <FontAwesomeIcon
            onClick={() => navigate(-1)}
            icon={faAngleRight}
            color={MAIN_PINK}
            size="l"
          />
        </Arrow>
      </Box>
    </>
  );
}
const Box = styled.div`
  display: flex;
  border-radius: 25px;
  overflow: hidden;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: white;
  box-shadow: rgb(0 0 0 / 25%) 0px 7px 10px;
  justify-content: space-between;
  align-items: center;
`;
const Left = styled.div`
  display: flex;
  align-items: center;
`;
const BuildImg = styled.div`
  width: 100px;
  height: 100px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const Content = styled.div`
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  .name {
    font-size: 18px;
    font-weight: bold;
  }
  .location {
    font-size: 12px;
    color: ${TEXT_GRAY};
  }
`;
const Arrow = styled.div`
  font-size: 30px;
  color: ${MAIN_PINK};
  margin-right: 20px;
`;
