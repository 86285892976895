import {
  collection,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { dbService } from "../../../fbase";
import Reply from "./Reply";

export default function Replies({ docId, postBid }) {
  const url = useLocation();
  const postUID = url.pathname.split("/")[2];
  const [replies, setReplies] = useState([]);
  useEffect(() => {
    const replyRef = query(
      collection(dbService, "buildings", postBid, "posts", docId, "replies"),
      orderBy("replyCreatedTime")
    );
    const realSnapShot = onSnapshot(replyRef, (snapshot) => {
      const array = [];
      snapshot.forEach((doc) => array.push(doc.data()));
      // console.log(array);
      setReplies(array);
    });
  }, []);
  return (
    <div style={{ marginTop: "30px" }}>
      {replies.map((e) => (
        <Reply data={e} />
      ))}
    </div>
  );
}
