import { addDoc, collection, doc, getDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faStar,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { MAIN_GRAY, MAIN_PINK, DISABLE_GRAY } from "../../color";
import { dbService } from "../../fbase";
import switchToDate from "../../function/switchToDate";
import Replies from "./reply/Replies";

export default function HotPost({ userObj, refreshUser }) {
  const state = useLocation().state.comment;
  const [data, setData] = useState();
  const [writerObj, setWriterObj] = useState();
  console.log();
  useEffect(async () => {
    const b = doc(dbService, "buildings", state.hotBID, "posts", state.hotPID);
    const q = await getDoc(b);
    if (q.exists()) {
      setData({ ...q.data(), id: q.id });
    } else {
      setData("something wrong");
    }
  }, []);
  const { year, month, day, hour, min } = switchToDate(state.hotCreatedTime);
  const [comment, setComment] = useState("");
  const onFormSubmit = (e) => {
    e.preventDefault();
    console.log(comment);
    const newReply = {
      replyBID: data.postBID,
      replyBody: comment,
      replyCreatedTime: new Date(),
      replyGood: 0,
      replyLiker: ["null exception 방지"],
      replyNickname: userObj.userNickname,
      replyPID: data.id,
      replyRID: null,
      replyReport: 0,
      replyUID: userObj.userUID,
    };
    console.log(newReply);
    const docRef = addDoc(
      collection(
        dbService,
        "buildings",
        data.postBID,
        "posts",
        data.id,
        "replies"
      ),
      newReply
    )
      .then(() => {
        console.log("success");
        setComment("");
      })
      .catch(console.error);
  };

  return data ? (
    <Container>
      <Top>
        <TitleMenu>
          <Title>
            <h3>{data.postTitle}</h3>
          </Title>
          <Menubar>...</Menubar>
        </TitleMenu>
        <Writter>
          <Profile>
            <img src="/img/profile/new_profile.png" alt="profile image" />
          </Profile>
          <NameTime>
            <p className="name">{data.postNickname}</p>
            <p className="time">{`${year}/${month}/${day} ${hour}:${min}`}</p>
          </NameTime>
          <Status></Status>
        </Writter>
      </Top>
      <Mid>
        <Content>{data.contentsList[0]}</Content>
        <PostImg>
          {data.contentsList[1] ? <img src={data.contentsList[1]} /> : null}
        </PostImg>
        <Tag>
          {data.keyword.map((e) => (e ? <span>{`#${e}`}</span> : null))}
        </Tag>
        <Icons>
          <Heart style={{ backgroundColor: DISABLE_GRAY }}>
            <Icon>
              <FontAwesomeIcon
                icon={faHeart}
                color="white"
                onClick={() =>
                  alert(
                    "베타 버전에서는 아직 인기글에 좋아요를 달 수 없어요 ㅠㅠ😂"
                  )
                }
              />
            </Icon>
            <Num>{data.postGood}</Num>
          </Heart>
          <Star>
            <Icon>
              <FontAwesomeIcon
                icon={faStar}
                color={MAIN_GRAY}
                onClick={() => alert("정식 버전에서 다시 만나요!😉")}
              />
            </Icon>
            {/* <Num>{data.postScrappers ? data.postScrappers.length : 0}</Num> */}
          </Star>
        </Icons>
      </Mid>
      <Bottom>
        <WriteComment onSubmit={onFormSubmit}>
          <InputBox
            type="text"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <SearchBtn onClick={onFormSubmit}>
            <FontAwesomeIcon icon={faPaperPlane} color={MAIN_PINK} />
          </SearchBtn>
        </WriteComment>
      </Bottom>
      <Replies docId={data.id} postBid={data.postBID} />
    </Container>
  ) : (
    <h1>wait...</h1>
  );
}
const Top = styled.div``;
const TitleMenu = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;
const Title = styled.div``;
const Menubar = styled.div``;
const Writter = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 5px;
  border-bottom: 1px solid ${MAIN_GRAY};
`;
const Profile = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${MAIN_GRAY};
  img {
    width: 100%;
  }
`;
const NameTime = styled.div`
  margin-left: 15px;
  p {
    margin: 0;
  }
`;
const Status = styled.div`
  color: ${MAIN_PINK};
  margin-left: 15px;
`;
const Mid = styled.div``;
const Content = styled.div`
  white-space: pre-wrap;
`;
const PostImg = styled.div`
  img {
    width: 100%;
  }
`;
const Tag = styled.div`
  display: flex;
  justify-content: center;
  color: ${MAIN_PINK};
`;
const Icons = styled.div`
  display: flex;
  background: ${MAIN_GRAY};
  border-radius: 40px;
  width: 100px;
  margin: auto;
  margin-top: 5px;
`;
const Icon = styled.div``;
const Num = styled.div`
  margin-left: 5px;
`;
const Heart = styled.div`
  display: flex;
  height: 100%;
  border-right: 2px solid white;
  width: 60%;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  color: white;
  background-color: ${MAIN_PINK};
  border-radius: 100px;
`;
const Star = styled(Heart)`
  border: 0;
  width: 40%;
  background-color: orange;
`;
const Bottom = styled.div``;
const WriteComment = styled.form`
  margin-bottom: 40px;
  display: flex;
  box-shadow: rgb(0 0 0 / 25%) 0px 7px 10px;
  border-radius: 15px;
  padding: 5px;
  background-color: white;
  width: 90%;
  margin: auto;
  margin-top: 20px;
`;
const InputBox = styled.input`
  border: 0;
  width: 90%;
  padding-left: 15px;
  :focus {
    outline: 0;
  }
`;
const SearchBtn = styled.div`
  width: 10%;
  /* background-color: ${MAIN_GRAY}; */
  img {
    width: 100%;
    filter: opacity(0.5) drop-shadow(0 0 0 ${MAIN_PINK});
  }
`;
