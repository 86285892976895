import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { MAIN_PINK, MAIN_GRAY, TEXT_GRAY } from "../../../color";
import switchToDate from "../../../function/switchToDate";
import { dbService } from "../../../fbase";
import { doc, getDoc } from "firebase/firestore";
export default function PostCard({ data }) {
  const { year, month, day, hour, min, date } = switchToDate(
    data.postCreatedTime
  );
  const navigate = useNavigate();
  const [buildingInfo, setBuildingInfo] = useState();
  const location = useLocation();
  const intervalHour =
    (new Date().getTime() - date.toMillis()) / (1000 * 60 * 60);
  const intervalMin = (new Date().getTime() - date.toMillis()) / (1000 * 60);
  const toPost = () => {
    navigate(`/post/${data.id}`, {
      state: { comment: data, prev: location.pathname },
    });
  };
  useEffect(() => {
    if (data.postBID) {
      const docRef = doc(dbService, "buildings", data.postBID);
      const docSnap = getDoc(docRef);
      const cardData = docSnap.then((docSnap) => {
        if (docSnap.exists()) {
          setBuildingInfo(docSnap.data());
          return docSnap.data();
        } else {
          console.log("error");
        }
      });
    }
  }, []);
  return (
    <CommentContainer onClick={toPost}>
      <FirstLine>
        <span>{data.postNickname}</span>
        <span>
          {intervalHour < 1
            ? `${Math.ceil(intervalMin)}분 전`
            : intervalHour < 24
            ? `${hour}:${min}`
            : `${month}/${day}`}
        </span>
      </FirstLine>
      <Title>{data.postTitle}</Title>
      <Content>{data.contentsList[0]}</Content>
      <ListLine>
        <span>
          {/* {data.keyword.map((e, i) => (
            <span key={i} className="tag">
              {e === "" ? null : "#" + e}
            </span>
          ))} */}
          {buildingInfo && buildingInfo.buildingName}
        </span>
        <span>
          <FontAwesomeIcon icon={faHeart} color={MAIN_PINK} />
          <span className="numHeart">{data.postGood}</span>
        </span>
      </ListLine>
    </CommentContainer>
  );
}
const CommentContainer = styled.div`
  margin: 20px 10px;
  padding: 10px;
  border-radius: 20px;
  font-size: 12px;
  box-shadow: rgb(0 0 0 / 25%) 0px 7px 10px;
  background-color: white;
`;
const FirstLine = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${TEXT_GRAY};
`;
const Title = styled.div`
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 15px;
`;
const Content = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
`;
const ListLine = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  color: rgb(206, 91, 91);
  .tag {
    margin-right: 5px;
  }
  .numHeart {
    color: ${TEXT_GRAY};
    margin-left: 5px;
  }
`;
