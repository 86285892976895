import { signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MAIN_PINK } from "../../color";
import { authService } from "../../fbase";
export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onChange = (event) => {
    const {
      target: { name, value },
    } = event;
    if (name === "email") {
      setEmail(value);
    } else if (name === "password") {
      setPassword(value);
    }
  };

  const PasswordReset = (event) => {
    const navigate = useNavigate();
    navigate("/sendPasswordResetEmail");
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      if (email === "" || password === "") {
        alert("모든 정보를 입력해주세요!");
        return;
      }

      const auth = authService;
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          const user = userCredential.user;
          // ...
          console.log("로그인 성공");
          navigate("/");
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log("로그인 실패1");
          alert("비밀번호가 틀렸습니다!");
        });
    } catch (error) {
      console.log(error);
      console.log("로그인 실패2");
      alert("알 수 없는 오류");
      //setError(error.message);
    }
  };

  return (
    <Container
      style={{
        height: window.innerHeight,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Logo>
        <img src="/img/new_logo_2.png" alt="logo" />
      </Logo>
      <LoginForm onSubmit={onSubmit}>
        <EmailInput
          name="email"
          type={"email"}
          placeholder="이메일"
          onChange={onChange}
        />
        <PwInput
          name="password"
          type={"password"}
          placeholder="비밀번호"
          onChange={onChange}
        />
        <a style={{ marginBottom: "30px" }} onClick={PasswordReset}>
          비밀번호를 잊으셨나요?
        </a>
        <LoginBtn type={"submit"} value="로그인" />
        <p>
          KUplace에 처음이신가요?{" "}
          <a className="red" href="/signup">
            회원가입
          </a>
        </p>
      </LoginForm>
      <Ask>문의하기</Ask>
    </Container>
  );
}
const Logo = styled.div`
  height: 25%;
  padding-top: 30%;
  img {
    width: 50%;
    max-width: 200px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
  }
`;
const LoginForm = styled.form`
  height: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-top: 20%;
  a {
    text-decoration: none;
  }
  a,
  p {
    font-size: 12px;
  }
  .red {
    color: ${MAIN_PINK};
  }
`;
const EmailInput = styled.input`
  width: 80%;
  font-size: 15px;
  height: 40px;
  padding: 0 15px;
  border-radius: 15px;
  border: 0;
  box-shadow: rgb(0 0 0 / 25%) 0px 7px 10px;
  margin-bottom: 20px;
`;
const PwInput = styled(EmailInput)``;
const LoginBtn = styled.input`
  color: white;
  background-color: ${MAIN_PINK};
  border-radius: 20px;
  padding: 5px 30px;
  border: 0;
  margin-bottom: 20px;
  box-shadow: rgb(255 68 94 / 40%) 0px 7px 10px;
`;
const Ask = styled.div`
  font-size: 12px;
  justify-self: flex-end;
`;
