import { Carousel } from "bootstrap";
import { collection, getDocs, query } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { CarouselItem } from "react-bootstrap";
import Slider from "react-slick";
import { dbService } from "../../fbase";
import Slide from "./Slide";

export default function Banner() {
  var settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const [events, setEvents] = useState([]);
  useEffect(() => {
    let eventArr = [];
    const eventsRef = query(collection(dbService, "events"));
    getDocs(eventsRef)
      .then((docs) =>
        docs.forEach((doc) => eventArr.push({ ...doc.data(), id: doc.id }))
      )
      .then(() => setEvents(eventArr));
  }, []);
  return (
    <Slider {...settings} style={{ width: "80%", margin: "auto" }}>
      {events.map((event) => (
        <Slide event={event} />
      ))}
    </Slider>
  );
}
