import { EmailAuthProvider, reauthenticateWithCredential, signInWithEmailAndPassword, updateEmail, updatePassword } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { authService ,dbService} from "../../fbase";


export default function Changepassword({userObj}) {
  const navigate = useNavigate();
  const [newpassword, setnewpassword] = useState("");
  const [password, setPassword] = useState("");
  const [newpasswordcheck,setnewpasswordcheck]=  useState("");
  const onChange = (event) => {
    const {
      target: { name, value },
    } = event;
    console.log(name,value);
    if (name === "newpassword") {
      console.log(value);
      setnewpassword(value);
    } else if (name === "password") {
      setPassword(value);
      console.log(value);
    } else if (name === "newpasswordcheck") {
        setnewpasswordcheck(value);
        console.log(value);
      } 
  };


  const onSubmit = async (event) => {
    event.preventDefault();
    try {


      if(newpassword === "" || password === "" || newpasswordcheck === ""){
        alert("모든 정보를 입력해주세요!")
        return;
      }
      
      if(newpassword !==  newpasswordcheck ){
        alert("비밀번호 확인이 새 비밀번호와 같지 않습니다!")
        return;
      }
      


      const auth = authService;
      const user =auth.currentUser;
      
      console.log(password);

      //이메일 정규식 


      // TODO(you): prompt the user to re-provide their sign-in credentials
const credential = EmailAuthProvider.credential(userObj.userEmail, password)

reauthenticateWithCredential(user, credential).then(() => {
  // User re-authenticated.
  console.log("재인증 성공")
  
  updatePassword(user, newpassword).then(() => {
    // Update successful.
    alert('비밀번호가 변경되었습니다!')
    navigate("/mypage/viewprofile");
  }).catch((error) => {
    // An error ocurred
    // ...
    console.log("비밀번호 변경 실패1")
    alert("알 수 없는 오류1")
  });



  

}).catch((error) => {
  // An error ocurred
  // ...
  console.log("재인증 실패")
  alert("기존 비밀번호가 맞지 않습니다!")
  return;
});



      
    } catch (error) {
        console.log(error);
        console.log("비밀번호 변경 실패2")
        alert("알 수 없는 오류2")
        //setError(error.message);
    }
  }

  
  return (
    <Container
      style={{
        height: window.innerHeight,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "whitesmoke",
      }}
    >
      <Logo>
        <span>비밀번호</span> 변경
      </Logo>
      <ChangePasswordForm onSubmit={onSubmit}>
       
        <PwInput  name="password" type={"password"} placeholder="기존 비밀번호" onChange={onChange}/>
        <PwInput  name="newpassword" type={"password"} placeholder="새 비밀번호" onChange={onChange}/>
        <PwInput  name="newpasswordcheck" type={"password"} placeholder="새 비밀번호 확인" onChange={onChange}/>
        <ChangePasswordBtn type={"submit"} value="변경하기" />
        
      </ChangePasswordForm>
     
    </Container>
  );
}
const Logo = styled.div`
  height: 30%;
  padding-top: 30%;
  color: red;
  font-size: 50px;
  text-transform: uppercase;
  span {
    font-weight: bolder;
  }
`;
const ChangePasswordForm = styled.form`
  height: 60%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-top: 20%;
  a {
    text-decoration: none;
  }
  a,
  p {
    font-size: 12px;
  }
  .red {
    color: red;
  }
`;
const EmailInput = styled.input`
  width: 80%;
  font-size: 15px;
  height: 40px;
  padding: 0 15px;
  border-radius: 15px;
  border: 0;
  box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.4);
  margin-bottom: 20px;
`;
const PwInput = styled(EmailInput)``;
const ChangePasswordBtn = styled.input`
  color: white;
  background-color: red;
  border-radius: 20px;
  padding: 5px 30px;
  border: 0;
  margin-bottom: 20px;
`;
const Ask = styled.div`
  font-size: 12px;
  justify-self: flex-end;
`;
