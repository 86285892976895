import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Body from "./Body";
import { authService, dbService } from "./fbase";
import Footer from "./Footer";
import Header from "./Header";
import { MAIN_GRAY, MAIN_PINK } from "./color";
function App() {
  const [userObj, setUserObj] = useState("");
  const [building, setBuilding] = useState([]);
  const [isLoggedIn, setisLoggedIn] = useState(false);
  // const [isLoggedIn, setisLoggedIn] = useState(true);
  const { pathname } = useLocation();
  const subpath = pathname.split("/");
  const navigate = useNavigate();
  useEffect(() => {
    authService.onAuthStateChanged((user) => {
      if (user) {
        const userUID = user.uid;
        const docRef = doc(dbService, "users", userUID);
        const docSnap = getDoc(docRef);
        const data = docSnap.then((docSnap) => {
          if (docSnap.exists()) {
            setUserObj(docSnap.data());
            setisLoggedIn(true);
            return docSnap.data();
          } else {
            console.log(
              "userUID : " + userUID + "에 해당하는 유저정보가 없습니다!"
            );
          }
        });
      } else {
        setUserObj("로그인되지 않음");
      }
    });
    const buildingRef = query(
      collection(dbService, "buildings"),
      orderBy("buildingName")
    );
    const buildingDocs = getDocs(buildingRef);
    let buildingArray = [];
    buildingDocs
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          buildingArray.push({ ...doc.data(), id: doc.id });
        });
      })
      .then(
        () =>
          (buildingArray = buildingArray.filter(
            (e) =>
              e.id !== "freedom" &&
              e.id !== "feedback" &&
              e.id !== "buildingNotice" &&
              e.id !== "question" &&
              e.id !== "festival"
          ))
      )
      .then(() => setBuilding(buildingArray))
      .catch(console.error);
  }, []);

  const refreshUser = () => {
    console.log("refreshUser 시작");
    const user = authService.currentUser;
    if (user) {
      const userUID = user.uid;
      const docRef = doc(dbService, "users", userUID);
      const docSnap = getDoc(docRef);
      docSnap.then((docSnap) => {
        if (docSnap.exists()) {
          setUserObj(docSnap.data());
        } else {
          setUserObj("로그인되지 않음");
          console.log("로그인되지 않음");
        }
      });
    } else {
      setUserObj("로그인되지 않음");
      console.log("로그인되지 않음");
    }
    return userObj;
  };
  const location = useLocation();
  const toWriting = () => {
    console.log(location.pathname);
    navigate(`/write`, {
      state: { prev: location.pathname },
    });
  };
  function DecideFooter() {
    if (subpath[1] === "search") return null;
    if (subpath[1] === "write") return null;
    if (isLoggedIn === false) return null;
    if (subpath[1] === "login" || subpath[1] == "signup") return null;
    if (subpath[1] === "post") return null;
    if (subpath[1] === "hotpost") return null;
    if (subpath[1] === "keyword" && subpath[2]) return null;
    if (subpath[1] === "board") {
      if (
        subpath[2] === "event" ||
        subpath[2] === "notice" ||
        subpath[2] === "new" ||
        subpath[2] === "hot"
      )
        return null;
    }
    if (subpath[2]) {
      if (subpath[1] === "mypage") return null;
      return <WriteBtn onClick={toWriting}>글쓰기</WriteBtn>;
    }
    return <Footer />;
  }
  return (
    <div
      style={{
        maxWidth: "420px",
        backgroundColor: ` ${MAIN_GRAY}`,
        margin: "0 auto",
        minHeight: `${window.innerHeight}px`,
      }}
    >
      <header>{isLoggedIn && <Header building={building} />}</header>
      {/* <header>{<Header building={building} />}</header> */}
      <main style={{ paddingBottom: "70px" }}>
        <Body
          refreshUser={refreshUser}
          isLoggedIn={isLoggedIn}
          setisLoggedIn={setisLoggedIn}
          userObj={userObj}
          setUserObj={setUserObj}
          building={building}
        />
      </main>
      <footer>{DecideFooter()}</footer>
    </div>
  );
}
const WriteBtn = styled.div`
  position: fixed;
  bottom: 20px;
  background: ${MAIN_PINK};
  color: white;
  border-radius: 25px;
  padding: 8px 15px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: rgb(255 68 94 / 40%) 0px 7px 10px;
  cursor: pointer;
`;
export default App;
