import { collection, getDocs, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import PostCard from "../../component/post/postCard/PostCard";
import { dbService } from "../../fbase";
import { switchCatAndKeyword } from "../../function/switchCatAndKeyword";
export default function KeywordComment() {
  const [data, setData] = useState([]);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const url = useLocation();
  const subpath = url.pathname.split("/");
  const result = switchCatAndKeyword(subpath[3], subpath[2]);
  const category = result.category;
  const keyword = result.keyword + "place";
  useEffect(async () => {
    const b = query(
      collection(dbService, "buildings"),
      where("buildingCategory", "==", category)
    );
    const queryKeyword = await getDocs(b);
    let array = [];
    queryKeyword.forEach((doc) => {
      array.push(doc.id);
    });
    setData(array);
    setLoading(false);
  }, []);
  useEffect(async () => {
    let postArr = [];
    data.length > 0 &&
      data.map(async (e, i) => {
        const q = query(
          collection(dbService, "buildings", e, "posts"),
          where("keyword", "array-contains", keyword)
        );
        const querySnapshotBuilding = await getDocs(q);
        querySnapshotBuilding.forEach((doc) => {
          postArr.push({ ...doc.data(), id: doc.id });
        });
        setPosts([...posts, ...postArr]);
      });
  }, [loading]);
  return (
    <Container>
      {posts.length > 0 ? (
        posts.map((e, i) => <PostCard data={e} key={i} />)
      ) : (
        <span>아직 게시글이 없습니다</span>
      )}
    </Container>
  );
}
