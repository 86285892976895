import { signOut } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { authService, dbService } from "../../fbase";
import { doc, updateDoc } from "firebase/firestore";
export default function Viewprofile({ userObj, setUserObj, refreshUser }) {
  const navigate = useNavigate();
  const [init, setInit] = useState(false);
  const [userInfo, setUserInfo] = useState("");

  const useremailverified = authService.currentUser.emailVerified;
  console.log(userInfo);
  useEffect(() => {
    if (userObj.userName) {
      console.log("userobj app.jsx에서 잘받아옴");
    } else {
      console.log("userobj app.jsx에서 못받아옴");
      const userinfo = refreshUser();
      setUserInfo(userinfo);
    }

    if (useremailverified && userObj.userKU) {
      //writingright 갱신코드
      const userRef = doc(dbService, "users", userObj.userUID);

      updateDoc(userRef, {
        writingRight: true,
      });
      console.log("writingRight갱신완료");
    }

    setInit(true);
  }, []);

  //비밀번호 변경
  const ChangePassword = async () => {
    navigate("/mypage/viewprofile/changePassword");
  };
  //이메일 변경
  const ChangeEmail = async () => {
    navigate("/mypage/viewprofile/changeEmail");
  };
  //닉네임 변경(광고보기)
  const ChangeNickname = async () => {
    navigate("/mypage/viewprofile/changeNickname");
  };

  return (
    <Container>
      {init ? (
        <Top>
          <h5>프로필</h5>
          <ContentBox>
            <ProfileImg>
              <img src="/img/profile/new_profile.png" alt="profile image" />
            </ProfileImg>
            <Contents>
              <Content>{`이메일 : ${userObj.userEmail}`}</Content>
              <Content>{`이름 : ${userObj.userName}`}</Content>
              <Content>{`닉네임 : ${userObj.userNickname}`}</Content>
              <Content>{`잔여 포인트 : ${userObj.userPoint}`}</Content>
              <Content>{`글쓰기 권한 : ${userObj.userEmail}`}</Content>
              <Content>{`이메일 인증여부 : ${
                userObj.writingRight ? "인증완료" : "인증되지 않음"
              }`}</Content>
            </Contents>
          </ContentBox>
        </Top>
      ) : (
        <>
          <span>불러오는 중</span>
        </>
      )}
      <BtnContainer>
        <EachBtn onClick={ChangePassword}>비밀번호 변경</EachBtn>
        <EachBtn onClick={ChangeEmail}>이메일 변경</EachBtn>
        <EachBtn onClick={ChangeNickname}>닉네임 변경(광고보기)</EachBtn>
        <HyperLink href="https://sites.google.com/view/kuplace-term-of-use">
          서비스 이용약관
        </HyperLink>
        <HyperLink href="https://sites.google.com/view/kuplace-privacy-policy">
          개인정보 처리방침
        </HyperLink>
      </BtnContainer>
    </Container>
  );
}
const Top = styled.div`
  border: 0.8px solid gray;
  border-radius: 15px;
  padding: 15px;
  margin-top: 15px;
  background-color: white;
`;
const ContentBox = styled.div`
  display: flex;
`;
const ProfileImg = styled.div`
  width: 100px;
  height: 100px;
  background-color: black;
  img {
    width: 100%;
  }
`;
const Contents = styled.div`
  margin-left: 15px;
`;
const Content = styled.div``;
const BtnContainer = styled.div`
  margin-top: 15px;
  border: 0.8px solid gray;
  border-radius: 15px;
  padding: 10px;
  background-color: white;
`;
const EachBtn = styled.div`
  margin: 15px 0;
  cursor: pointer;
`;
const HyperLink = styled.a`
  display: block;
  text-decoration: none;
  color: inherit;
  margin: 15px 0;
`;
