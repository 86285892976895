import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import PostCard from "../../component/post/postCard/PostCard";
import styled from "styled-components";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
} from "firebase/firestore";
import { dbService } from "../../fbase";
import { MAIN_PINK, TEXT_GRAY } from "../../color";

export default function BuildingNotice() {
  const [tab, setTab] = useState(0);
  const [posts, setPosts] = useState([]);
  const [description, setDescription] = useState(null);
  useEffect(async () => {
    const docRef = doc(dbService, "buildings", "buildingNotice");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setDescription(docSnap.data());
    } else {
      console.log("No such document!");
    }
    const questionRef = query(
      collection(dbService, "buildings", "buildingNotice", "posts"),
      orderBy(tab === 0 ? "postCreatedTime" : "postGood", "desc")
    );
    const questionDocs = getDocs(questionRef);
    let arr = [];
    questionDocs
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          arr.push({ ...doc.data(), id: doc.id });
        });
      })
      .then(() => {
        setPosts(arr);
      })
      .catch(console.error);
  }, [tab]);
  return (
    <Container>
      <Top>
        <h6>{description && description.buildingCategory}</h6>
        <p style={{ wordBreak: "keep-all" }}>
          {description && description.buildingInfo}
        </p>
      </Top>
      <TabBar>
        <Tab onClick={() => setTab(0)} active={tab === 0}>
          최근
        </Tab>
        <span className="sep">|</span>
        <Tab onClick={() => setTab(1)} active={tab === 1}>
          인기
        </Tab>
      </TabBar>
      {posts.map((e, i) => (
        <PostCard data={e} key={i} />
      ))}
    </Container>
  );
}
const Top = styled.div`
  margin-top: 20px;
  padding: 10px 15px;
  width: 95%;
  margin: auto;
  border-radius: 20px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: white;
`;
const Btn = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: ${MAIN_PINK};
  color: white;
  border-radius: 20px;
  padding: 5px 10px;
`;
const TabBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 15px;
  .sep {
    margin: 0 5px;
  }
`;
const Tab = styled.div`
  color: ${(props) => (props.active ? MAIN_PINK : TEXT_GRAY)};
  cursor: pointer;
`;
