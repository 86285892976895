import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function EventNotice({ setClicked }) {
  const navigate = useNavigate();
  const onBlackClicked = () => {
    setClicked(false);
  };
  const onListClicked = (e) => {
    navigate(`/board/${e.target.id}`);
    window.scroll(0, 0);
  };
  return (
    <Container onClick={onBlackClicked}>
      <Modal>
        <List id={"event"} onClick={onListClicked}>
          이벤트
        </List>
        <List id={"notice"} onClick={onListClicked}>
          공지사항
        </List>
      </Modal>
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  justify-content: center;
  align-items: center;
`;
const Modal = styled.div`
  background-color: white;
  width: 80%;
  max-width: 375px;
  padding: 20px 10px;
  z-index: 6;
  border-radius: 20px;
`;
const List = styled.div`
  padding: 10px 0;
  cursor: pointer;
`;
