import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { switchCatAndKeyword } from "./function/switchCatAndKeyword";
import { MAIN_GRAY, MAIN_PINK } from "./color";
export default function Header({ building }) {
  const url = useLocation();
  const subpath = url.pathname.split("/");
  const navigate = useNavigate();
  if (subpath[1] === "search") {
    return null;
  }
  if (subpath[1] === "keyword" && subpath[2]) {
    const result = switchCatAndKeyword(subpath[3], subpath[2]);
    return (
      <HeadTitle>
        <div className="kHeader">{result.keyword}place</div>
        <p className="subHeader">{result.category}</p>
      </HeadTitle>
    );
  }
  if (subpath[1] === "building" && subpath[2]) {
    const exactBuilding = building.find((e) => e.id === subpath[2]);
    return (
      <HeadTitle>
        <div className="bHeader">
          {exactBuilding && exactBuilding.buildingName}
        </div>
      </HeadTitle>
    );
  }
  if (subpath[1] === "board") {
    if (subpath[2] === "new")
      return (
        <HeadTitle>
          <div className="bHeader">최신글</div>
        </HeadTitle>
      );
    if (subpath[2] === "hot")
      return (
        <HeadTitle>
          <div className="bHeader">인기글</div>
        </HeadTitle>
      );
    if (subpath[2] === "event")
      return (
        <HeadTitle>
          <div className="bHeader" onClick={() => navigate("/board/event")}>
            이벤트
          </div>
        </HeadTitle>
      );
    if (subpath[2] === "notice")
      return (
        <HeadTitle>
          <div className="bHeader" onClick={() => navigate("/board/notice")}>
            공지사항
          </div>
        </HeadTitle>
      );
  }
  return (
    <Logo onClick={() => navigate("/")}>
      <img src="/img/new_logo_2.png" alt="logo" />
    </Logo>
  );
}
const Logo = styled.div`
  width: 100%;
  display: flex;
  padding-top: 15px;
  justify-content: center;
  background-color: ${MAIN_GRAY};
  img {
    width: 30%;
  }
`;
const HeadTitle = styled.div`
  background-color: ${MAIN_GRAY};
  cursor: pointer;
  .bHeader {
    color: ${MAIN_PINK};
    padding: 10px 0;
    display: flex;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
  }
  .kHeader {
    color: ${MAIN_PINK};
    display: flex;
    justify-content: center;
    font-size: 30px;
    font-weight: bold;
    padding-top: 10px;
  }
  .subHeader {
    text-align: center;
  }
`;
