import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import PostCard from "../../component/post/postCard/PostCard";
import { dbService } from "../../fbase";

export default function MyScrap({ userObj, building }) {
  const { userUID } = userObj;
  const [myScraps, setMyScraps] = useState([]);
  useEffect(async () => {
    // let buildingId = [];
    // const idsRef = query(collection(dbService, "buildings"));
    // const idsDoc = await getDocs(idsRef);
    // let arr = [];
    // idsDoc.forEach((doc) => {
    //   buildingId.push(doc.id);
    // });

    // buildingId.map(async (e, i) => {
    //   const allbuildings = query(
    //     collection(dbService, "buildings", e, "posts"),
    //     where("postScrappers", "array-contains", userUID)
    //   );
    //   const myScrapsDoc = await getDocs(allbuildings);
    //   myScrapsDoc.forEach((doc) => {
    //     console.log(doc.data());
    //     arr.push({ ...doc.data(), id: doc.id });
    //   });
    // });
    // setMyScraps(arr);
    let arr = [];
    building.map(async (e, i) => {
      const allbuildings = query(
        collection(dbService, "buildings", e.id, "posts"),
        where("postScrappers", "array-contains", userUID)
      );
      const myScrapsDoc = await getDocs(allbuildings);
      myScrapsDoc.forEach((doc) => {
        // console.log(doc.data());
        arr.push({ ...doc.data(), id: doc.id });
      });
    });
    const buildingNotice = query(
      collection(dbService, "buildings", "buildingNotice", "posts"),
      where("postScrappers", "array-contains", userUID)
    );
    const bNoticeDoc = await getDocs(buildingNotice);
    bNoticeDoc.forEach((doc) => {
      // console.log(doc.data());
      arr.push({ ...doc.data(), id: doc.id });
    });
    const questionRef = query(
      collection(dbService, "buildings", "question", "posts"),
      where("postScrappers", "array-contains", userUID)
    );
    const questionDocs = getDocs(questionRef);
    questionDocs
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          // console.log(doc.data());
          arr.push({ ...doc.data(), id: doc.id });
        });
      })
      .then(() => {
        // console.log(arr);
        arr.sort(function (a, b) {
          // Compare the 2 dates
          if (a.postCreatedTime.seconds > b.postCreatedTime.seconds) return -1;
          if (a.postCreatedTime.seconds < b.postCreatedTime.seconds) return 1;
          return 0;
        });
        // console.log(arr);
      })
      .then(() => {
        setMyScraps(arr);
      })
      .catch(console.error);
  }, []);
  console.log(myScraps);
  return (
    <Container>
      {myScraps.length === 0 ? (
        <h6 style={{ marginTop: "30px", textAlign: "center" }}>
          아직 스크랩한 글이 없네요..😅
          <br />글 둘러보러 가보시는 건 어때요?
        </h6>
      ) : (
        myScraps.map((e, i) => <PostCard data={e} key={i} />)
      )}
    </Container>
  );
}
