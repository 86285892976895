import { Timestamp } from "firebase/firestore";

export default function (time) {
  const date = new Timestamp(time.seconds, time.nanoseconds);
  const year = date.toDate().getFullYear();
  const month = (date.toDate().getMonth() + 1).toString().padStart(2, "0");
  const day = date.toDate().getDate().toString().padStart(2, "0");
  const hour = date.toDate().getHours().toString().padStart(2, "0");
  const min = date.toDate().getMinutes().toString().padStart(2, "0");
  return { date, year, month, day, hour, min };
}
