import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { MAIN_GRAY, MAIN_PINK, TEXT_GRAY } from "../../color";
import PostCard from "../../component/post/postCard/PostCard";
import { dbService } from "../../fbase";

export default function SpecificBuilding() {
  const { buildingID } = useParams();
  const [buildingData, setBuildingData] = useState({});
  const [buildingPosts, setBuildingPosts] = useState([]);
  const handleScroll = () => {
    const scrollHeight = document.documentElement.scrollHeight;
    const scrollTop = document.documentElement.scrollTop;
    const clientHeight = document.documentElement.clientHeight;
    // if (scrollTop + clientHeight >= scrollHeight) {
    //   // 페이지 끝에 도달하면 추가 데이터를 받아온다
    //   console.log("끝에 닿았어요!");
    // }
  };
  const [tab, setTab] = useState(0);
  useEffect(async () => {
    const b = query(
      collection(dbService, "buildings", buildingID, "posts"),
      orderBy(
        tab === 0 ? "postCreatedTime" : tab === 1 ? "postGood" : null,
        "desc"
      )
      // ,limit(5)
    );
    // const questionB = query(
    //   collection(dbService, "buildings", "question", "posts"),
    //   where("keyword", "array-contains", "SK미래관(에미관)")
    // );
    // const buildingDocs = getDocs(tab === 2 ? questionB : b);
    const buildingDocs = getDocs(b);
    let array = [];
    buildingDocs
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          array.push({ ...doc.data(), id: doc.id });
        });
      })
      .then(() => setBuildingPosts(array))
      .catch(console.error);
    const docRef = doc(dbService, "buildings", buildingID);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setBuildingData(docSnap.data());
    } else {
      console.log("No such document!");
    }
  }, [tab]);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  });
  return (
    <Container>
      <Top>
        <h6>{buildingData.buildingCategory}</h6>
        <p className="info">{buildingData.buildingInfo}</p>
        <Btn onClick={() => alert("정식 버전에서 다시 만나요!😉")}>
          건물 구경하기
        </Btn>
      </Top>
      <TabBar>
        <Tab onClick={() => setTab(0)} active={tab === 0}>
          최근
        </Tab>
        <span className="sep">|</span>
        <Tab onClick={() => setTab(1)} active={tab === 1}>
          인기
        </Tab>
        {/* <span className="sep">|</span> */}
        {/* <Tab onClick={() => setTab(2)} active={tab === 2}>
          질문
        </Tab> */}
      </TabBar>
      {buildingPosts.map((e, i) => (
        <PostCard data={e} key={i} />
      ))}
    </Container>
  );
}
const Top = styled.div`
  padding: 15px;
  width: 95%;
  margin: auto;
  box-shadow: rgb(0 0 0 / 25%) 0px 7px 10px;
  border-radius: 15px;
  position: relative;
  margin-bottom: 20px;
  .info {
    color: ${TEXT_GRAY};
    font-size: 12px;
  }
  background-color: white;
`;
const Btn = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: ${MAIN_PINK};
  color: white;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 12px;
`;
const TabBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 15px;
  .sep {
    margin: 0 5px;
  }
`;
const Tab = styled.div`
  color: ${(props) => (props.active ? MAIN_PINK : TEXT_GRAY)};
  cursor: pointer;
`;
