import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import PostCard from "../../component/post/postCard/PostCard";
import styled from "styled-components";
import {
  collection,
  getDocs,
  orderBy,
  query,
  collectionGroup,
  limit,
} from "firebase/firestore";
import { dbService } from "../../fbase";
import { MAIN_PINK, TEXT_GRAY } from "../../color";

export default function Newest() {
  const [posts, setPosts] = useState([]);

  useEffect(async () => {
    let postArr = [];
    const AllPosts = query(
      collectionGroup(dbService, "posts"),
      orderBy("postCreatedTime", "desc"),
      limit(30)
    );
    const querySnapshot = await getDocs(AllPosts);
    querySnapshot.forEach((doc) => {
      postArr.push({ ...doc.data(), id: doc.id });
    });
    setPosts(postArr);
  }, []);

  return (
    <Container>
      {/* <Top>
        <h6>자유롭게 학교 시설에 대해 질문하는 공간</h6>
        <p>질문에 답글을 달아주시면 30 KUKIE를 드립니다.</p>
      </Top> */}

      {posts.map((e, i) => {
        return <PostCard data={e} key={i} />;
      })}
    </Container>
  );
}
const Top = styled.div`
  margin-top: 20px;
  padding: 10px;
  width: 95%;
  margin: auto;
  border-radius: 20px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: white;
`;
const Btn = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: ${MAIN_PINK};
  color: white;
  border-radius: 20px;
  padding: 5px 10px;
`;
const TabBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 15px;
  .sep {
    margin: 0 5px;
  }
`;
const Tab = styled.div`
  color: ${(props) => (props.active ? MAIN_PINK : TEXT_GRAY)};
  cursor: pointer;
`;
