import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { MAIN_GRAY, MAIN_PINK, TEXT_GRAY } from "../../color";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import switchToDate from "../../function/switchToDate";

export default function EventPost() {
  const data = useLocation().state.comment;
  const [img, setImg] = useState("initial");
  const storage = getStorage();
  const { year, month, day, hour, min } = switchToDate(data.eventCreatedTime);

  useEffect(() => {
    getDownloadURL(ref(storage, `EventImg/${data.id}.png`)).then((url) =>
      setImg(url)
    );
  });
  return (
    <Container style={{ backgroundColor: "white" }}>
      <Top>
        <img src={img} />
      </Top>
      <Mid>
        <Content>
          <Title>{data.eventTitle}</Title>
          <p>{data.eventBody}</p>
          <p className="writer">
            Kuplace<span>{`${year}/${month}/${day}  ${hour}:${min}`}</span>
          </p>
        </Content>
      </Mid>
    </Container>
  );
}
const Top = styled.div`
  img {
    width: 100%;
  }
`;

const Title = styled.div`
  font-weight: bold;
  margin-top: 10px;
`;
const Mid = styled.div``;
const Content = styled.div`
  white-space: pre-wrap;
  word-break: keep-all;
  p {
    margin-top: 10px;
  }
  .writer {
    margin-top: 10px;
    color: ${TEXT_GRAY};
    span {
      margin-left: 10px;
    }
  }
`;
