import { getAuth, sendEmailVerification, signOut } from "firebase/auth";
import React from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MAIN_GRAY, TEXT_GRAY } from "../../color";
import { authService } from "../../fbase";

export default function Mypage({ userObj, setUserObj, setisLoggedIn }) {
  if (userObj) {
    console.log("userobj app.jsx에서 잘받아옴");
  } else {
    console.log("userobj app.jsx에서 못받아옴");
  }
  console.log(userObj);
  const navigate = useNavigate();
  const useremailverified = authService.currentUser.emailVerified;

  //탈퇴

  const cancelmembership = async () => {
    navigate("/mypage/cancelmembership");
  };

  //로그아웃
  const logout = async () => {
    setisLoggedIn(false);
    const auth = authService;
    await signOut(auth);
    navigate("/login");
  };
  const sendEmailVerificationfunc = () => {
    if (!useremailverified) {
      const auth = getAuth();
      sendEmailVerification(auth.currentUser).then(() => {
        // Email verification sent!
        // ...
        alert("인증이메일이 전송되었습니다.");
      });
    } else {
      alert("이메일 인증이 이미 완료되었습니다.");
    }
  };
  return (
    <Container>
      <ContentBoxImg>
        <LeftImg>
          <img src="/img/profile/new_profile.png" alt="profile image" />
        </LeftImg>
        <Right>
          <p>{userObj.userNickname}</p>
          <p className="certification">
            {userObj.writingRight ? "이메일인증 완료" : "이메일인증 안됨"}
          </p>
        </Right>
      </ContentBoxImg>
      <ContentBox>
        <Content
          onClick={() =>
            alert("상점은 아직 준비중이에요😅\n조금만 기다려주세요!")
          }
        >
          상점
        </Content>
      </ContentBox>
      <ContentBox>
        <Content onClick={() => navigate("/mypage/scrap")}>스크랩</Content>
        <Content onClick={() => navigate("/mypage/myposts")}>
          내가 쓴 글
        </Content>
      </ContentBox>
      <ContentBox>
        <Content onClick={() => navigate("/mypage/viewProfile")}>
          프로필 보기
        </Content>
        <Content onClick={sendEmailVerificationfunc}>이메일 인증</Content>
      </ContentBox>
      <ContentBox>
        <Content
          onClick={() => alert("@Kuplace_ 인스타에서 이용방법을 확인해보세요!")}
        >
          이용방법
        </Content>
        <Content
          onClick={() => window.open("https://open.kakao.com/o/ssbTSDPd")}
        >
          문의하기
        </Content>
        <Content onClick={() => alert("정식 버전에서 다시 만나요!😉")}>
          오픈소스 라이센스
        </Content>
      </ContentBox>
      <ContentBox>
        <Content onClick={logout}>로그아웃</Content>
      </ContentBox>
    </Container>
  );
}
const ContentBox = styled.div`
  width: 100%;
  border: 0.8px solid gray;
  margin: 12px 0;
  border-radius: 15px;
  padding: 10px;
  background-color: white;
`;
const ContentBoxImg = styled(ContentBox)`
  display: flex;
  align-items: center;
  box-shadow: rgb(0 0 0 / 25%) 0px 7px 10px;
  background-color: white;
`;
const LeftImg = styled.div`
  width: 50px;
  height: 50px;
  background-color: tomato;
  img {
    width: 100%;
  }
`;
const Right = styled.div`
  margin-left: 10px;
  p {
    margin: 0;
  }
  .certification {
    font-size: 10px;
    color: ${TEXT_GRAY};
  }
`;
const Content = styled.div`
  padding: 8px 0;
  cursor: pointer;
`;
