import React, { useState } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import BuildingCategory from "../../component/building/BuildingCategory";
import { Keyworddata } from "../../data/data";
export default function KeyWord() {
  const [id, setId] = useState(null);
  const [clicked, setClicked] = useState(false);
  return (
    <>
      <Container>
        <GridBox>
          {Keyworddata.map((e, i) => (
            <div key={i} className="box">
              <div
                onClick={() => {
                  setId(e.id);
                  setClicked(true);
                }}
                className="innercontent"
              >
                <img src={`/img/keyword/${e.img}.png`} alt="mask" />
                <span>{e.word} place</span>
              </div>
            </div>
          ))}
        </GridBox>
      </Container>
      {clicked ? <BuildingCategory id={id} setClicked={setClicked} /> : null}
    </>
  );
}
const GridBox = styled.div`
  display: grid;
  grid-template-columns: 45% 45%;
  justify-content: space-around;
  grid-row-gap: 30px;
  padding-top: 20px;
  padding-bottom: 30px;
  .box {
    background-color: white;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    box-shadow: rgb(0 0 0 / 25%) 0px 7px 10px;
    border-radius: 20px;
    position: relative;
    .innercontent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 100%;
      height: 100%;
      img {
        width: 70%;
        margin: auto;
      }
    }
  }
`;
