export const Boarddata = [
  { name: "질문 게시판", img: "question" },
  { name: "이벤트/공지", img: "event" },
  { name: "시설 공지", img: "buildingNotice" },
  { name: "최신글", img: "new" },
  { name: "인기글", img: "hot" },
  { name: "축제 게시판", img: "festival" },
];

export const Keyworddata = [
  { id: 1, word: "비대면", img: "cammike" },
  { id: 2, word: "팀플", img: "teample" },
  { id: 3, word: "수면", img: "sleep_kwd" },
  { id: 4, word: "밥", img: "eat" },
  { id: 5, word: "공부", img: "study" },
  { id: 6, word: "콘센트", img: "consent" },
  { id: 7, word: "휴식", img: "rest_kwd" },
  { id: 8, word: "발표", img: "ppt" },
  { id: 9, word: "토크", img: "talk" },
  { id: 10, word: "노트북", img: "laptop" },
  { id: 11, word: "조용", img: "donttalk" },
  { id: 12, word: "추천", img: "chucheon" },
];
export const ExamHotData = [
  {
    hotGood: 0,
    hotKeyword: ["공학관", "", ""],
    hotContent: "건물탭 들어가서 사진보니까 공사중이던데 아직도 하는건가요??",
    hotUID: "W5jWpETob7fvZmO9rEWx6le1NJu1",
    hotBID: "question",
    hotPID: "D6RgC1hHRmSLziTpv6JF",
    hotTitle: "공학관 공사",
    hotCreatedTime: {
      seconds: 1646107703,
      nanoseconds: 822000000,
    },
    hotNickname: "구름구름",
  },
];

export const frequentSearch = [
  "정수기",
  "열람실",
  "스터디룸",
  "라운지",
  "여성용품",
  "도서관",
  "식당",
  "카페",
  "편의점",
  "자판기",
  "프린트",
];
