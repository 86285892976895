import React, { useState } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import {
  createUserWithEmailAndPassword,
  getAuth,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useForm } from "react-hook-form";
import { authService, dbService } from "../../fbase";
import { doc, setDoc } from "firebase/firestore";
import { async } from "@firebase/util";
import { Navigate, useNavigate } from "react-router-dom";
import { MAIN_GRAY, MAIN_PINK, TEXT_GRAY } from "../../color";
export default function Signup() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      userEmail: "@korea.ac.kr",
    },
  });
  const onValid = (data) => {
    if (password !== ConfirmPassword) {
      return alert("비밀번호 확인이 일치하지 않습니다.");
    }
    if (!(termOfUseCheck && privacyPolicyCheck)) {
      return alert("이용약관에 모두 동의해주세요!");
    }

    createUserWithEmailAndPassword(authService, email, password)
      .then(async (userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log(user);
        const userUID = await user.uid;
        const userKU = email.includes("@korea.ac.kr");
        console.log(userUID);
        const userData = {
          likePost: [],
          userEmail: data.userEmail,
          userKU: userKU,
          userName: data.userName,
          userNickname: data.userNickname,
          userPoint: 0,
          userUID: userUID,
          wrightingRight: false,
        };
        //user db에서 식별 불가해서 test db 만들고 실험해봤더니 잘 작동하는 코드입니다!
        setDoc(doc(dbService, "users", userUID), userData)
          .then(() => console.log("success"))
          .catch(console.error);
      })
      .then(() => navigate("/"))
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(error);
        alert("이미 가입된 이메일 입니다!!")
        // ..
      });
  };
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [termOfUseCheck, setTermOfUse] = useState(false);
  const [privacyPolicyCheck, setPrivacyPolicyCheck] = useState(false);
  const onChange = (event) => {
    const {
      target: { name, value },
    } = event;
    if (name === "userEmail") {
      setEmail(value);
    } else if (name === "userPW") {
      setPassword(value);
    } else if (name === "PWCheck") {
      setConfirmPassword(value);
    }
  };

  const toggleCheck1 = () => setTermOfUse((prev) => !prev);
  const toggleCheck2 = () => setPrivacyPolicyCheck((prev) => !prev);
  return (
    <Container
      style={{
        height: window.innerHeight,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <SignForm onSubmit={handleSubmit(onValid)}>
        <InputAndWarnig>
          <Name
            {...register("userName", { required: "write here" })}
            type={"text"}
            placeholder="이름"
          />
          <p className="star">*이후 변경 불가</p>
          <span>{errors?.userName?.message}</span>
        </InputAndWarnig>
        <InputAndWarnig>
          <Email
            {...register("userEmail", {
              required: "Email is required",
              pattern: {
                value: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/,
                message: "Only korea.ac.kr emails allowed",
              },
            })}
            type={"email"}
            placeholder="이메일 (sample@korea.ac.kr)"
            onChange={onChange}
          />
          <p className="star">*타 이메일로 가입 시 질문과 댓글만 작성 가능</p>
          <span>{errors?.userEmail?.message}</span>
        </InputAndWarnig>
        <InputAndWarnig>
          <Nickname
            {...register("userNickname", {
              required: "닉네임을 입력해주세요 !",
              minLength: {
                value: 2,
                message: "닉네임의 최소 길이는 2입니다!",
              },
              maxLength: {
                value: 10,
                message: "닉네임의 최대 길이는 10입니다!",
              },
              pattern: {
                value: /^[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|]+$/,
                message: "한글,영어,숫자로 구성된 2~10자의 닉네임만 가능합니다",
              },
            })}
            type={"text"}
            placeholder="닉네임"
          />
          <p className="star">*공백 없이 한글, 영문, 숫자만 입력가능(2~10자)</p>
          <span>{errors?.userNickname?.message}</span>
        </InputAndWarnig>
        <InputAndWarnig>
          <Pw
            {...register("userPW", {
              required: "비밀번호를 적어주세요",
              minLength: {
                value: 6,
                message: "비밀번호를 6자 이상이어야 합니다!",
              },
            })}
            type={"password"}
            placeholder="비밀번호"
            onChange={onChange}
          />
          <p className="star">*공백 입력 불가능(6자 이상)</p>
          <span>{errors?.userPW?.message}</span>
        </InputAndWarnig>
        <PwCheck
          {...register("PWCheck", {
            required: "비밀번호 확인을 입력해주세요!",
            minLength: {
              value: 6,
              message: "비밀번호를 6자 이상이어야 합니다!",
            },
          })}
          type={"password"}
          placeholder="비밀번호 확인"
          onChange={onChange}
        />
        <span>{errors?.PWCheck?.message}</span>

        <Check>
          <div>
            <input type={"checkbox"} onClick={toggleCheck1} />
            <span>
              [필수]
              <a
                href="https://sites.google.com/view/kuplace-term-of-use"
                target="_blank"
                className="red"
              >
                서비스이용약관
              </a>
              에 동의합니다.
            </span>
          </div>
          <div>
            <input type={"checkbox"} onClick={toggleCheck2} />
            <span>
              [필수]
              <a
                href="https://sites.google.com/view/kuplace-privacy-policy"
                target="_blank"
                className="red"
              >
                개인정보 취급방침
              </a>
              에 동의합니다.
            </span>
          </div>
        </Check>
        <SignBtn type={"submit"} value="회원가입" />
      </SignForm>
    </Container>
  );
}
const SignForm = styled.form`
  width: 100%;
  display: flex;
  margin: auto;
  height: 80%;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
`;
const InputAndWarnig = styled.div`
  width: 80%;
  .star {
    text-align: left;
    align-self: flex-start;
    margin: 0;
    margin-top: 5px;
    color: ${TEXT_GRAY};
    font-size: 10px;
  }
`;
const Name = styled.input`
  width: 100%;
  font-size: 15px;
  height: 50px;
  padding: 0 15px;
  border-radius: 15px;
  border: 0;
  box-shadow: rgb(0 0 0 / 25%) 0px 7px 10px;
`;
const Email = styled(Name)``;
const Nickname = styled(Name)``;
const Pw = styled(Name)``;
const PwCheck = styled(Name)`
  width: 80%;
`;
const Check = styled.div`
  margin-left: 20%;
  align-self: flex-start;
  font-size: 12px;
  div {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  input {
    margin-right: 10px;
  }
  .red {
    color: ${MAIN_PINK};
    text-decoration: none;
  }
`;
const SignBtn = styled.input`
  color: white;
  background-color: ${MAIN_PINK};
  border-radius: 20px;
  padding: 5px 30px;
  border: 0;
  box-shadow: rgb(255 68 94 / 40%) 0px 7px 10px;
`;
