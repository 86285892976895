
import { doc, updateDoc } from "firebase/firestore";
import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { authService ,dbService} from "../../fbase";


export default function Changenickname({userObj}) {
  const navigate = useNavigate();
  const [nickname, setnickname] = useState("");
  

  const onChange = (event) => {
    const {
      target: { name, value },
    } = event;
    console.log(name,value);
    if (name === "nickname") {
      console.log(value);
      setnickname(value);
    } 
  };


  const onSubmit = async (event) => {
    event.preventDefault();
    try {


      if(nickname === "" ){
        alert("모든 정보를 입력해주세요!")
        return;
      }

      if (nickname.includes("!") || nickname.includes("~") || nickname.includes("@") || nickname.includes("#") ||
      nickname.includes("$") || nickname.includes("%") || nickname.includes("^") || nickname.includes("&") ||
      nickname.includes("*") || nickname.includes("(") || nickname.includes(")") ||
      nickname.includes("_") ||nickname.includes("-") || nickname.includes("+") ||
      nickname.includes("=") || nickname.includes("<") || nickname.includes(">") ||
      nickname.includes(",") || nickname.includes(".") || nickname.includes("/") ||
      nickname.includes("?") || nickname.includes(";") || nickname.includes(":") ||
      nickname.includes("'") || nickname.includes("|") || nickname.includes("[") ||
      nickname.includes("]") || nickname.includes("{") || nickname.includes("}") ||
      nickname.includes("`") || nickname.includes(" ") ){
  alert("닉네임에 공백 또는 특수문자를 포함할 수 없습니다");
  return;
}
if(nickname.includes("관리자")===true || nickname.includes("운영자")===true ||
      nickname.includes("KUplace")===true || nickname.includes("webmaster")===true ||
      nickname==="고려대학교"){
        alert("사용할 수없는 닉네임입니다!");
  return;
}
if (nickname.length <2 || nickname.length > 10){
    alert("글자수가 맞지 않습니다!");
  return;
}




      const auth = authService;
      
      console.log(nickname);
      

     





  

const userRef = doc(dbService, "users", userObj.userUID);


updateDoc(userRef, {

userNickname:nickname
});

alert('닉네임이 변경되었습니다!')
navigate("/mypage/viewprofile");
  





      
    } catch (error) {
        console.log(error);
        console.log("닉네임 실패2")
        alert("알 수 없는 오류")
        //setError(error.message);
    }
  }

  
  return (
    <Container
      style={{
        height: window.innerHeight,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "whitesmoke",
      }}
    >
      <Logo>
        <span>닉네임</span> 변경
      </Logo>
      <ChangenicknameForm onSubmit={onSubmit}>
        <NicknameInput  name="nickname" type={"name"} placeholder="새 닉네임" onChange={onChange}/>
        <span>* 공백 없이 한글, 영문, 숫자만 입력가능 2~10자</span>
       
        <ChangenicknameBtn type={"submit"} value="변경하기" />
        
      </ChangenicknameForm>
     
    </Container>
  );
}
const Logo = styled.div`
  height: 30%;
  padding-top: 30%;
  color: red;
  font-size: 50px;
  text-transform: uppercase;
  span {
    font-weight: bolder;
  }
`;
const ChangenicknameForm = styled.form`
  height: 60%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-top: 20%;
  a {
    text-decoration: none;
  }
  a,
  p {
    font-size: 12px;
  }
  .red {
    color: red;
  }
`;
const NicknameInput = styled.input`
  width: 80%;
  font-size: 15px;
  height: 40px;
  padding: 0 15px;
  border-radius: 15px;
  border: 0;
  box-shadow: 2px 5px 5px rgba(0, 0, 0, 0.4);
  margin-bottom: 20px;
`;

const ChangenicknameBtn = styled.input`
  color: white;
  background-color: red;
  border-radius: 20px;
  padding: 5px 30px;
  border: 0;
  margin-bottom: 20px;
`;
const Ask = styled.div`
  font-size: 12px;
  justify-self: flex-end;
`;
