import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import switchToDate from "../../../function/switchToDate";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { MAIN_GRAY, MAIN_PINK } from "../../../color";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Timestamp } from "firebase/firestore";
export default function Reply({ data }) {
  const { replyNickname, replyBody, replyGood, replyCreatedTime } = data;
  const { year, month, day, hour, min, date } = switchToDate(replyCreatedTime);
  console.log(day, hour, min);
  const intervalHour =
    (new Date().getTime() - date.toMillis()) / (1000 * 60 * 60);
  const intervalMin = (new Date().getTime() - date.toMillis()) / (1000 * 60);
  return (
    <Container
      style={{
        backgroundColor: "white",
        margin: "15px auto",
        borderRadius: "20px",
        width: "90%",
        padding: "10px",
      }}
    >
      <Top>
        <Writer>{replyNickname}</Writer>
        <Bar>...</Bar>
      </Top>
      <Mid>
        <Content>{replyBody}</Content>
      </Mid>
      <Bottom>
        <RDate>
          {intervalHour < 1
            ? `${Math.ceil(intervalMin)}분 전`
            : intervalHour < 24
            ? `${hour}:${min}`
            : `${month}/${day}`}
        </RDate>
        <Heart>
          <FontAwesomeIcon icon={faHeart} color="gray" />
          <span style={{ color: MAIN_PINK, marginLeft: "5px" }}>
            {replyGood}
          </span>
        </Heart>
      </Bottom>
    </Container>
  );
}
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const Writer = styled.div`
  font-weight: bold;
`;
const Bar = styled.div``;
const Mid = styled.div`
  margin: 10px 0;
`;
const Content = styled.div`
  word-break: keep-all;
`;
const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
`;
const RDate = styled.div`
  color: gray;
  font-size: 14px;
`;
const Heart = styled.div`
  font-size: 14px;
`;
