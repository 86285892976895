import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import PostCard from "../../component/post/postCard/PostCard";
import styled from "styled-components";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { dbService } from "../../fbase";
import { MAIN_GRAY, MAIN_PINK, TEXT_GRAY } from "../../color";

export default function Festival() {
  const [tab, setTab] = useState(0);
  const [posts, setPosts] = useState([]);
  const [description, setDescription] = useState();
  useEffect(async () => {
    const docRef = doc(dbService, "buildings", "festival");
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setDescription(docSnap.data());
    } else {
      console.log("No such document!");
    }
    const festivalRef = query(
      collection(dbService, "buildings", "festival", "posts"),
      where(
        "keyword",
        tab === 0 ? "array-contains-any" : "array-contains",
        tab === 1 ? "행사 후기" : tab === 2 ? "사진" : ["행사 후기", "사진"]
      ),

      orderBy("postCreatedTime", "desc")
    );
    const festivalDocs = getDocs(festivalRef);
    let arr = [];
    festivalDocs
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          arr.push({ ...doc.data(), id: doc.id });
        });
      })
      .then(() => {
        setPosts(arr);
      })
      .catch(console.error);
  }, [tab]);
  return (
    <Container>
      <Top>
        <h6>{description && description.buildingCategory}</h6>
        <p>{description && description.buildingInfo}</p>
      </Top>
      <TabBar>
        <Tab onClick={() => setTab(0)} active={tab === 0}>
          전체
        </Tab>
        <span className="sep">|</span>
        <Tab onClick={() => setTab(1)} active={tab === 1}>
          행사 후기
        </Tab>
        <span className="sep">|</span>
        <Tab onClick={() => setTab(2)} active={tab === 2}>
          사진
        </Tab>
      </TabBar>
      {posts.map((e, i) => (
        <PostCard data={e} key={i} />
      ))}
    </Container>
  );
}
const Top = styled.div`
  margin-top: 20px;
  padding: 10px;
  width: 95%;
  margin: auto;
  border-radius: 20px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: white;
`;
const Btn = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: ${MAIN_PINK};
  color: white;
  border-radius: 20px;
  padding: 5px 10px;
`;
const TabBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 15px;
  .sep {
    margin: 0 5px;
  }
`;
const Tab = styled.div`
  color: ${(props) => (props.active ? MAIN_PINK : TEXT_GRAY)};
  cursor: pointer;
`;
