import React from "react";

export function switchCatAndKeyword(categoryId, keywordId) {
  let category, keyword;
  switch (categoryId) {
    case "1":
      category = "인문계";
      break;
    case "2":
      category = "이공계";
      break;
    case "3":
      category = "의과대 및 기숙사";
      break;
  }
  switch (keywordId) {
    case "1":
      keyword = "비대면";
      break;
    case "2":
      keyword = "팀플";
      break;
    case "3":
      keyword = "수면";
      break;
    case "4":
      keyword = "밥";
      break;
    case "5":
      keyword = "공부";
      break;
    case "6":
      keyword = "콘센트";
      break;
    case "7":
      keyword = "휴식";
      break;
    case "8":
      keyword = "발표";
      break;
    case "9":
      keyword = "토크";
      break;
    case "10":
      keyword = "노트북";
      break;
    case "11":
      keyword = "조용";
      break;
    case "12":
      keyword = "추천";
      break;
  }
  return { category, keyword };
}
export function switchBoard(board) {
  switch (board) {
    case "question":
      return "질문";
  }
}
