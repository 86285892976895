import React from "react";
import { Container } from "react-bootstrap";
import Banner from "../../component/home/Banner";
import Boards from "../../component/board/Boards";

export default function Home() {
  return (
    <Container>
      <Banner />
      <Boards />
    </Container>
  );
}
