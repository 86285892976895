import { getDownloadURL, getStorage, ref } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function Slide({ event }) {
  const [img, setImg] = useState("initial");
  const storage = getStorage();
  const navigate = useNavigate();
  useEffect(() => {
    getDownloadURL(ref(storage, `EventImg/${event.id}.png`)).then((url) =>
      setImg(url)
    );
  });
  const onEventClicked = () => {
    navigate(`/board/event/${event.id}`, {
      state: { comment: event },
    });
  };
  return (
    <EachSlide onClick={onEventClicked}>
      <img src={img} alt="/" />
      <p>{event.eventTitle}</p>
    </EachSlide>
  );
}
const EachSlide = styled.div`
  margin: 5px 0px;
  img {
    width: 90%;
    height: 150px;
    margin: auto;
    border-radius: 20px;
    object-fit: cover;
  }
  p {
    text-align: center;
    margin-top: 10px;
  }
`;
