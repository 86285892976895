import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { dbService } from "../../fbase";
import { MAIN_GRAY, MAIN_PINK, TEXT_GRAY } from "../../color";
import EventPostCard from "../../component/post/postCard/EventPostCard";
export default function Event() {
  const [events, setEvents] = useState([]);
  useEffect(() => {
    const eventsRef = query(
      collection(dbService, "events"),
      orderBy("eventCreatedTime", "desc")
    );

    const eventsDocs = getDocs(eventsRef);
    let arr = [];
    eventsDocs
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          arr.push({ ...doc.data(), id: doc.id });
        });
      })
      .then(() => {
        setEvents(arr);
      })
      .catch(console.error);
  }, []);
  return (
    <Container>
      {/* <Top>
        <h6>자유롭게 학교 시설에 대해 질문하는 공간</h6>
        <p>질문에 답글을 달아주시면 30 KUKIE를 드립니다.</p>
      </Top> */}
      {events.map((e, i) => (
        <EventPostCard data={e} key={i} />
      ))}
    </Container>
  );
}
const Top = styled.div`
  margin-top: 20px;
  padding: 10px;
  width: 95%;
  margin: auto;
  border-radius: 20px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: white;
`;
const Btn = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: ${MAIN_PINK};
  color: white;
  border-radius: 20px;
  padding: 5px 10px;
`;
const TabBar = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  font-size: 15px;
  .sep {
    margin: 0 5px;
  }
`;
const Tab = styled.div`
  color: ${(props) => (props.active ? MAIN_PINK : TEXT_GRAY)};
  cursor: pointer;
`;
