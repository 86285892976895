import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
export default function Footer() {
  const { pathname } = useLocation();
  const subpath = pathname.split("/");
  return (
    <FooterBox>
      <IconBox>
        <a href="/">
          <img
            src={`/img/footerBtn/btn_1${subpath[1] === "" ? "" : "_off"}.png`}
            alt="home button"
          />
        </a>
      </IconBox>
      <IconBox>
        <a href="/building">
          <img
            src={`/img/footerBtn/btn_2${
              subpath[1] === "building" ? "" : "_off"
            }.png`}
            alt="building button"
          />
        </a>
      </IconBox>
      <SearchBox
        onClick={() =>
          alert("아직 검색 기능은 준비중이에요 ㅠㅠ😂\n조금만 기다려주세요!")
        }
      >
        {/* <a href="/search"> */}
        <img src={`/img/footerBtn/btn_search_round.png`} alt="search button" />
        {/* </a> */}
      </SearchBox>
      <IconBox>
        <a href="/keyword">
          <img
            src={`/img/footerBtn/btn_3${
              subpath[1] === "keyword" ? "" : "_off"
            }.png`}
            alt="keyword button"
          />
        </a>
      </IconBox>
      <IconBox>
        <a href="/mypage">
          <img
            src={`/img/footerBtn/btn_4${
              subpath[1] === "mypage" ? "" : "_off"
            }.png`}
            alt="mypage button"
          />
        </a>
      </IconBox>
    </FooterBox>
  );
}

const FooterBox = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 420px;
  height: 70px;
  padding: 0 10px;
  border-radius: 30px 30px 0 0;
  background-color: white;
  display: flex;
  a {
    color: inherit;
  }
`;
const IconBox = styled.div`
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    width: 40%;
  }
`;
const SearchBox = styled(IconBox)`
  margin-top: -35px;
  height: 100%;
  img {
    border-radius: 50%;
    width: 85%;
    box-shadow: rgb(255 68 94 / 40%) 0px 7px 10px;
  }
`;
