import React from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { MAIN_PINK, MAIN_GRAY, TEXT_GRAY } from "../../../color";
import switchToDate from "../../../function/switchToDate";
export default function EventPostCard({ data }) {
  const { year, month, day, hour, min } = switchToDate(data.eventCreatedTime);

  const navigate = useNavigate();
  const location = useLocation();
  const toPost = () => {
    navigate(`/board/event/${data.id}`, {
      state: { comment: data, prev: location.pathname },
    });
  };

  return (
    <CommentContainer onClick={toPost}>
      <Title>{data.eventTitle}</Title>
      <Content>{data.eventBody}</Content>
      <ListLine>
        <span>Kuplace</span>
        <span className="date">{`${month}/${day}`}</span>
      </ListLine>
    </CommentContainer>
  );
}
const CommentContainer = styled.div`
  margin: 20px 10px;
  padding: 10px;
  border-radius: 20px;
  font-size: 12px;
  box-shadow: rgb(0 0 0 / 25%) 0px 7px 10px;
  background-color: white;
`;
const FirstLine = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${TEXT_GRAY};
`;
const Title = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 15px;
`;
const Content = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
`;
const ListLine = styled.div`
  margin-top: 5px;
  display: flex;
  /* justify-content: space-between; */
  color: ${TEXT_GRAY};
  .date {
    margin-left: 15px;
  }
`;
