import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function SelectFestivalCategory({ setClicked, setkeyword }) {
  const [select, setSelect] = useState();
  const onBlackClicked = () => {
    setkeyword([]);
    setClicked(false);
  };

  const onListClicked = (e) => {
    setkeyword([select]);
    setClicked(false);
  };
  return (
    <Container>
      <Modal>
        <h5 className="title">카테고리를 선택해주세요</h5>
        {/* <div className="selectionBox" style={{ marginTop: "60px" }}> */}
        <div className="selection" onClick={() => setSelect("행사 후기")}>
          <List id={0} type="radio" name="category" />
          <label for={0}>행사 후기</label>
        </div>

        <div className="selection" onClick={() => setSelect("사진")}>
          <List id={1} type="radio" name="category" />
          <label for={1}>사진</label>
        </div>
        {/* </div> */}
        <Bottom>
          <span onClick={onBlackClicked}>선택 취소</span>
          <span onClick={onListClicked}>선택 완료</span>
        </Bottom>
      </Modal>
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  position: absolute;
  z-index: 25;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  justify-content: center;
  overflow-y: scroll;
  height: ${window.innerHeight + "px"};
  align-items: center;
`;
const Modal = styled.div`
  background-color: white;
  width: 80%;
  max-width: 375px;
  z-index: 6;
  overflow-y: scroll;
  position: relative;
  .title {
    background-color: white;
    padding: 10px 20px;
    width: 100%;
  }
  .selection {
    font-size: 20px;
    margin: 10px 0;
    padding: 5px 15px;
    label {
      margin-left: 20px;
    }
  }
  .sep {
    width: 100%;
    height: 1px;
    background-color: black;
  }
`;
const List = styled.input`
  cursor: pointer;
`;
const Bottom = styled.div`
  background-color: white;
  font-size: 15px;
  padding: 10px;
  color: green;
  display: flex;
  justify-content: flex-end;

  width: 100%;
  span {
    margin-left: 10px;
    cursor: pointer;
  }
`;
