import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

export default function BuildingCategory({ id, setClicked }) {
  const navigate = useNavigate();
  const onBlackClicked = () => {
    setClicked(false);
  };
  const onListClicked = (e) => {
    navigate(`/keyword/${id}/${e.target.id}`);
    window.scroll(0, 0);
  };
  return (
    <Container onClick={onBlackClicked}>
      <Modal>
        <h2>카테고리</h2>
        <List id={1} onClick={onListClicked}>
          인문계
        </List>
        <List id={2} onClick={onListClicked}>
          이공계
        </List>
        <List id={3} onClick={onListClicked}>
          의과대 및 기숙사
        </List>
      </Modal>
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  justify-content: center;
  align-items: center;
`;
const Modal = styled.div`
  background-color: white;
  width: 80%;
  max-width: 375px;
  padding: 20px 10px;
  z-index: 6;
  border-radius: 20px;
`;
const List = styled.div`
  padding: 10px 0;
  cursor: pointer;
`;
