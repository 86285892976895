import React from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from "react-router-dom";
import Question from "./page/board/Question";
import Building from "./page/building/Building";
import SpecificBuilding from "./page/building/SpecificBuilding";
import Home from "./page/home/Home";
import KeyWord from "./page/keyword/KeyWord";
import KeywordComment from "./page/keyword/KeywordComment";
import Login from "./page/login&signin/Login";
import Signup from "./page/login&signin/Signup";
import Mypage from "./page/mypage/Mypage";
import Search from "./page/search/Search";
import Write from "./page/write/Write";
import Algoria from "./page/Algoria";
import Viewprofile from "./page/mypage/Viewprofile";
import Changeemail from "./page/mypage/ChangeEmail";
import Changepassword from "./page/mypage/Changepassword";
import Changenickname from "./page/mypage/Changenickname";
import Cancelmembership from "./page/mypage/Cancelmembership";
import Newest from "./page/board/Newest";
import Hot from "./page/board/Hot";
import Event from "./page/board/Event";
import Notice from "./page/board/Notice";
import Festival from "./page/board/Festival";
import Post from "./component/post/Post";
import HotPost from "./component/post/HotPost";
import NoticePost from "./component/post/NoticePost";
import EventPost from "./component/post/EventPost";
import MyScrap from "./page/mypage/MyScrap";
import MyPosts from "./page/mypage/MyPosts";
import BuildingNotice from "./page/board/BuildingNotice";
import Error from "./Error";

export default function Body({
  userObj,
  setUserObj,
  isLoggedIn,
  refreshUser,
  setisLoggedIn,
  building,
}) {
  return (
    <>
      <Routes>
        {isLoggedIn ? (
          <>
            // When logged in
            {/* Home */}
            <Route path="/" element={<Home />} />
            {/* Building */}
            <Route
              path="/building"
              element={<Building building={building} />}
            />
            <Route
              path="/building/:buildingID"
              element={<SpecificBuilding />}
            />
            {/* Search */}
            <Route path="/search" element={<Search />} />
            <Route path="/algoria" element={<Algoria />} />
            {/* Keyword */}
            <Route path="/keyword" element={<KeyWord />} />
            <Route
              path="/keyword/:keywordId/:cat"
              element={<KeywordComment />}
            />
            {/* Mypage */}
            <Route
              path="/mypage"
              element={
                <Mypage
                  userObj={userObj}
                  setUserObj={setUserObj}
                  setisLoggedIn={setisLoggedIn}
                />
              }
            />
            <Route
              path="/mypage/viewprofile"
              element={
                <Viewprofile
                  userObj={userObj}
                  setUserObj={setUserObj}
                  refreshUser={refreshUser}
                />
              }
            />
            <Route
              path="/mypage/scrap"
              element={<MyScrap userObj={userObj} building={building} />}
            />
            <Route
              path="/mypage/myposts"
              element={<MyPosts userObj={userObj} building={building} />}
            />
            <Route
              path="/mypage/viewprofile/changeEmail"
              element={<Changeemail userObj={userObj} />}
            ></Route>
            <Route
              path="/mypage/viewprofile/changepassword"
              element={<Changepassword userObj={userObj} />}
            ></Route>
            <Route
              path="/mypage/viewprofile/changenickname"
              element={<Changenickname userObj={userObj} />}
            ></Route>
            <Route
              path="/mypage/cancelmembership"
              element={<Cancelmembership userObj={userObj} />}
            ></Route>
            {/* Board(question, event, notice,buildingNotice, new, hot festival) */}
            <Route path="/board/question" element={<Question />} />
            <Route path="/board/event" element={<Event />} />
            <Route path="/board/event/:id" element={<EventPost />} />
            <Route path="/board/notice" element={<Notice />} />
            <Route path="/board/notice/:id" element={<NoticePost />} />
            <Route path="/board/buildingNotice" element={<BuildingNotice />} />
            <Route path="/board/new" element={<Newest building={building} />} />
            <Route path="/board/hot" element={<Hot />} />
            <Route path="/board/festival" element={<Festival />} />
            {/* Post */}
            <Route
              path="/post/:id"
              element={<Post userObj={userObj} refreshUser={refreshUser} />}
            />
            <Route
              path="/hotpost/:id"
              element={<HotPost userObj={userObj} refreshUser={refreshUser} />}
            />
            {/* Posting */}
            <Route
              path="/write"
              element={<Write building={building} userObj={userObj} />}
            />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="*" element={<Navigate to="/" />} />
          </>
        ) : (
          // When logged out
          <>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            {/* <Route path="*" element={<Home />} /> */}
            {/* <Route
              path="/sendPasswordResetEmail"
              element={<SendPasswordResetEmail />}
            /> */}
          </>
        )}
      </Routes>
    </>
  );
}
