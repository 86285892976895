import {
  addDoc,
  collection,
  doc,
  getDoc,
  increment,
  setDoc,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { MAIN_GRAY, MAIN_PINK, TEXT_GRAY } from "../../color";
import SelectBuilding from "../../component/modal/SelectBuilding";
import SelectFestivalCategory from "../../component/modal/SelectFestivalCategory";
import SelectKeyWord from "../../component/modal/SelectKeyWord";
import { dbService, storageService } from "../../fbase";
import { ref, uploadString, getDownloadURL } from "@firebase/storage";

export default function Write({ building, userObj }) {
  const { prev } = useLocation().state;
  const path = prev.split("/");
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [clicked, setClicked] = useState(false);
  const [contentsList, setcontentsList] = useState(false);
  const [whichBoard, setWhichBoard] = useState("");
  const [buildingName, setBuildingName] = useState("");
  const [keyword, setkeyword] = useState([]);
  const [imgUrl, setImgUrl] = useState();
  const navigate = useNavigate();
  const imgRef = useRef();
  useEffect(() => {
    console.log(contentsList);
  }, [contentsList]);
  useEffect(async () => {
    if (!userObj.writingRight) {
      if (path[2] !== "question") {
        alert("고려대 계정으로 접속한 후 이메일 인증을 해주세요!🐯");
        navigate("/");
      }
    } else {
      if (path[1] === "board") {
        if (path[2] === "question") {
          setWhichBoard("질문 게시판");
        } else if (path[2] === "festival") {
          setWhichBoard("축제 게시판");
        }
      } else if (path[1] === "building") {
        const b = doc(dbService, "buildings", path[2]);
        const q = await getDoc(b);
        if (q.exists()) {
          console.log(q.data().buildingName);
          setWhichBoard(q.data());
        } else {
          // setData("something wrong");
        }
      }
    }
  }, []);

  const onChangeImage = () => {
    const reader = new FileReader();
    const file = imgRef.current.files[0];
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImgUrl(reader.result);
    };
  };
  const onClickFileBtn = (e) => {
    imgRef.current.click();
  };
  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      if (title === "" || content === "") {
        alert("모든 정보를 입력해주세요!");
        return;
      }
      if (path[2] === "festival") {
        if (keyword.length === 0) {
          alert("축제게시판에서는 키워드 선택을 필수로 해주셔야 합니다!");
          return;
        }
      }
      if (!userObj) {
        alert("로그인 상태가 아닙니다!");
        navigate("/");
      }
      const documentReference = doc(dbService, "users", userObj.userUID);
      let contentslist = [];
      let formatlist = [];

      if (imgUrl) {
        const fileRef = ref(storageService, `posts/${documentReference.id}/0`);
        const response = await uploadString(fileRef, imgUrl, "data_url");
        console.log(response);
        const attachmentUrl = await getDownloadURL(response.ref);
        contentslist = [content, attachmentUrl];
        console.log(contentsList);

        formatlist = ["text", "image"];
      } else {
        contentslist = [content];
        formatlist = ["text"];
      }
      console.log(contentslist);

      const newpost = {
        postBID: path[2], //건물 아이디
        postBDC:
          path[1] === "building"
            ? whichBoard.buildingCategory
            : path[2] === "question"
            ? "자유롭게 학교 시설에 대해 질문하는 공간"
            : "학교 축제에 대해 공유하는 공간", //건물 카테고리
        postUID: userObj.userUID,
        postNickname: userObj.userNickname,
        postTitle: title,
        contentsList: contentslist,
        formatList: formatlist,
        keyword:
          keyword.length === 1
            ? [keyword[0], "", ""]
            : keyword.length === 2
            ? [keyword[0], keyword[1], ""]
            : keyword.length === 3
            ? keyword
            : ["선택안함", "", ""], //키워드 리스트
        postCreatedTime: new Date(),
        postGood: 0,
        postScrappers: null,
        postReport: 0,
      };
      console.log(newpost);

      const docRef = addDoc(
        collection(dbService, "buildings", path[2], "posts"),
        newpost
      )
        .then(async () => {
          console.log("success");
          const uRef = doc(dbService, "users", userObj.userUID);
          const result = await updateDoc(uRef, {
            userPoint:
              path[1] === "building"
                ? increment(50)
                : path[2] === "festival"
                ? increment(30)
                : increment(0),
          });
          navigate(`/${path[1]}/${path[2]}`);
        })
        .catch(console.error);
      console.log(docRef.id);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Container style={{ width: "90%" }}>
        <Top>
          <InputBox
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="제목"
          />
        </Top>
        <Mid>
          <SelectBox>
            {keyword.length === 0 ? (
              <span className="select" onClick={() => setClicked(true)}>
                키워드 선택
              </span>
            ) : path[1] === "building" ? (
              keyword.map((e) => <Selected>{e}</Selected>)
            ) : (
              <Selected>{keyword.map((e) => e)}</Selected>
            )}
          </SelectBox>

          <ContentBox>
            <ContentArea
              placeholder={`게시물 작성 시 금지사항\n\n1. 범죄, 불법 행위 등 법령을 위반하는 행위\n2.성적 수치심을 유발하는 행위\n3.정치 및 사회 관련 행위\n4. 허위사실을 게시하는 행위\n5. 비하, 차별, 욕설, 폭력 관련 내용을 작성하는 행위\n6. 이밖의 타인의 권리를 침해하는 행위\n\n내용을 입력하세요.`}
              required
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
            <img src={imgUrl ? imgUrl : ""} width="50"></img>
          </ContentBox>
        </Mid>
        <Footer>
          <span className="path">
            {path[1] === "board"
              ? whichBoard
              : path[1] === "building"
              ? whichBoard && whichBoard.buildingName
              : ""}
          </span>
          <Btns>
            <Btn color={MAIN_GRAY} onClick={onClickFileBtn}>
              이미지 첨부
            </Btn>
            <input
              type="file"
              ref={imgRef}
              accept="image/*"
              onChange={onChangeImage}
              style={{ display: "none" }}
            ></input>
            <Btn color={MAIN_PINK} onClick={onSubmit}>
              등록
            </Btn>
          </Btns>
        </Footer>
      </Container>
      {clicked ? (
        path[1] === "building" ? (
          <SelectKeyWord setClicked={setClicked} setkeyword={setkeyword} />
        ) : path[2] === "festival" ? (
          <SelectFestivalCategory
            setClicked={setClicked}
            setkeyword={setkeyword}
          />
        ) : (
          <SelectBuilding
            building={building}
            setBuildingName={setBuildingName}
            setClicked={setClicked}
            setkeyword={setkeyword}
          />
        )
      ) : null}
    </>
  );
}
const Top = styled.div`
  margin: 20px 0;
`;
const InputBox = styled.input`
  border: 0;
  border-radius: 20px;
  padding: 10px 20px;
  width: 100%;
  padding-left: 15px;
  :focus {
    outline: 0;
  }
`;
const Mid = styled.div``;
const SelectBox = styled.div`
  cursor: pointer;
  margin: 10px 0;
  .select {
    color: white;
    background-color: grey;
    padding: 10px 25px;
    border-radius: 20px;
  }
`;
const Notice = styled.div`
  margin: 20px 0;
  color: ${TEXT_GRAY};
  font-size: 12px;
`;
const ContentBox = styled.div``;
const ContentArea = styled.textarea`
  outline: 0;
  border: none;
  background-color: inherit;
  width: 100%;
  resize: none;
  height: 300px;
`;
const Footer = styled.div`
  position: absolute;
  background-color: white;
  color: black;
  bottom: 0px;
  width: 100%;
  max-width: 420px;
  height: 60px;
  z-index: 15;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  align-items: center;
  justify-content: space-between;
  .path {
    margin-left: 20px;
  }
`;
const Btns = styled.div`
  display: flex;
  align-items: center;
`;
const Btn = styled.div`
  background-color: ${(props) => props.color};
  padding: 5px 15px;
  border-radius: 20px;
  margin-left: 15px;
  color: white;
  cursor: pointer;
`;
const Selected = styled.span`
  border-radius: 20px;
  border: 1px solid ${MAIN_PINK};
  color: ${MAIN_PINK};
  padding: 5px 10px;
  margin-right: 10px;
`;
