import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Keyworddata } from "../../data/data";

export default function SelectKeyWord({ setClicked, setkeyword }) {
  const [select, setSelect] = useState([]);
  const onBlackClicked = () => {
    setkeyword([]);
    setClicked(false);
  };

  const onListClicked = (e) => {
    if (select.length > 3) {
      alert(
        "키워드는 최대 3개까지만 선택할 수 있습니다! 제거 후 다시 시도해주세요."
      );
      return;
    }
    setkeyword(select);
    setClicked(false);
  };
  return (
    <Container>
      <Modal>
        <h5 className="title">해당하는 카테고리를 최대 3개까지 선택해주세요</h5>
        <div style={{ marginTop: "70px" }}>
          {Keyworddata.map((e) => (
            <div className="selection">
              <List
                id={e.word}
                type="checkbox"
                onChange={(e) => {
                  if (e.target.checked) {
                    let a = select;
                    a.push(e.target.id + "place");
                    setSelect(a);
                  } else {
                    let a = select;
                    a.pop(e.target.id + "place");
                    setSelect(a);
                  }
                }}
              />
              <label for={e.word}>{e.word}</label>
            </div>
          ))}
        </div>
        <Bottom>
          <span onClick={onBlackClicked}>선택 취소</span>
          <span onClick={onListClicked}>선택 완료</span>
        </Bottom>
      </Modal>
    </Container>
  );
}
const Container = styled.div`
  display: flex;
  position: absolute;
  z-index: 25;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  justify-content: center;
  overflow-y: scroll;
  height: ${window.innerHeight + "px"};
  align-items: center;
`;
const Modal = styled.div`
  background-color: white;
  width: 80%;
  max-width: 375px;
  z-index: 6;
  height: 90%;
  overflow-y: scroll;
  position: relative;
  .title {
    word-break: keep-all;
    position: fixed;
    top: 5%;
    background-color: white;
    padding: 10px 0;
    max-width: 375px;
    width: 80%;
    border-bottom: 1px solid black;
  }
  .selection {
    font-size: 20px;
    margin: 10px 0;
    padding: 5px 15px;
    label {
      margin-left: 20px;
    }
  }
  .sep {
    width: 100%;
    height: 1px;
    background-color: black;
  }
`;
const List = styled.input`
  cursor: pointer;
`;
const Bottom = styled.div`
  position: fixed;
  bottom: 5%;
  background-color: white;
  font-size: 15px;
  padding: 10px;
  color: green;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid black;
  max-width: 375px;
  width: 80%;
  span {
    margin-left: 10px;
    cursor: pointer;
  }
`;
